import { fl } from './florida-cams'
import { pt } from './portugal-cams'
import { es } from './spain-cams'
import { fr } from './france-cams'
import { uk } from './uk-cams'
export default {
  fl,
  pt,
  es,
  fr,
  uk
}
