export const uk = {
  country: 'UK',
  title: 'UK Beach Cams',
  beaches: [
    {
      name: 'North Fistral Beach',
      url: 'https://cams.cdn-surfline.com/cdn-int/uk-fistral/playlist.m3u8'
    },
    {
      name: 'North Fistral Beach Overview',
      url: 'https://cams.cdn-surfline.com/cdn-int/uk-fistralov/playlist.m3u8'
    },
    {
      name: 'South Fistral',
      url: 'https://cams.cdn-surfline.com/cdn-int/uk-fistralsouth/playlist.m3u8'
    },
    {
      name: 'Watergate Bay Front',
      url:
        'https://cams.cdn-surfline.com/cdn-int/uk-watergatebayfront/playlist.m3u8'
    },
    {
      name: 'Watergate Bay',
      url: 'https://cams.cdn-surfline.com/cdn-int/uk-watergatebay/playlist.m3u8'
    },
    {
      name: 'Mawgan Porth Overview',
      url:
        'https://cams.cdn-surfline.com/cdn-int/uk-mawganoverview/playlist.m3u8'
    }
  ]
}
