import { setTypes } from '../redux-utils';

const types = [
    'CREATE_MEDIA',
    'CREATE_MEDIA_PROGRESS',
    'SET_ALL_MEDIA',
    'FETCH_ALL_MEDIA',
    'SET_SELECTED_FEEDBACK',
    'TOGGLE_FEEDBACK',
];

export default setTypes(types);
