export const pt = {
  country: 'Portugal',
  title: 'Portugal Beach Cams',
  beaches: [
    // {
    //   name: 'Arrifana (Surfline)',
    //   url: 'https://cams.cdn-surfline.com/cdn-int/pt-arrifana/playlist.m3u8'
    // },
    {
      name: 'Monte Clerigo',
      url: 'https://video-auth1.iol.pt/beachcam/bcmonteclerigo/playlist.m3u8'
    },
    {
      name: 'Arrifana (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/arrifana/playlist.m3u8'
    },
    {
      name: 'Praia do Peneco',
      url: 'https://video-auth1.iol.pt/beachcam/praiadopeneco/playlist.m3u8'
    },
    {
      name: 'Senhora da Rocha e Praia Nova',
      url: 'https://video-auth1.iol.pt/beachcam/bcigreja/playlist.m3u8'
    },
    {
      name: 'Praia da Rocha',
      url: 'https://video-auth1.iol.pt/beachcam/praiadarocha/playlist.m3u8'
    },
    {
      name: 'Foz do Lizandro',
      url: 'https://video-auth1.iol.pt/beachcam/fozdolizandro/playlist.m3u8'
    },
    {
      name: 'Ericeira - Praia do Sul',
      url:
        'https://video-auth1.iol.pt/beachcam/praiadosulericeira/playlist.m3u8'
    },
    {
      name: 'Praia dos Pescadores',
      url: 'https://video-auth1.iol.pt/beachcam/bcpescadores/playlist.m3u8'
    },
    {
      name: 'Ericeira',
      url: 'https://video-auth1.iol.pt/beachcam/ericeiraspots/playlist.m3u8'
    },
    {
      name: "Ribeira D'Ilhas",
      url: 'https://video-auth1.iol.pt/beachcam/bcmafraribeira/playlist.m3u8'
    },
    {
      name: 'Santa Cruz',
      url: 'https://video-auth1.iol.pt/beachcam/santacruz/playlist.m3u8'
    },
    {
      name: 'Peniche - Lagide',
      url: 'https://video-auth1.iol.pt/beachcam/lagide/playlist.m3u8'
    },
    {
      name: 'Madeira - Jardim do Mar',
      url:
        'https://video-auth1.iol.pt/beachcam/madeirajardimdomar/playlist.m3u8'
    },
    {
      name: 'Alvor',
      url: 'https://video-auth1.iol.pt/beachcam/alvor/playlist.m3u8'
    },
    {
      name: 'Mareta - Sagres',
      url: 'https://camsecure.co/HLS/sagressholidays.m3u8'
    },
    {
      name: 'Luz (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/luz/playlist.m3u8'
    },
    // {
    //   name: 'Luz (Surfline)',
    //   url: 'https://cams.cdn-surfline.com/cdn-int/pt-praiadaluz/playlist.m3u8'
    // },
    {
      name: 'Lagos (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/meiapraia/playlist.m3u8'
    },
    // {
    //   name: 'Lagos (Surfline)',
    //   url: 'https://cams.cdn-surfline.com/cdn-int/pt-meiapraia/playlist.m3u8'
    // },

    {
      name: 'Amoreira',
      url: 'https://video-auth1.iol.pt/beachcam/bcamoreira/playlist.m3u8'
    },
    {
      name: 'Zambujeira do Mar',
      url: 'https://video-auth1.iol.pt/beachcam/zambujeira/playlist.m3u8'
    },
    // {
    //   name: 'Peniche - Super Tubos (Surfline)',
    //   url: 'https://cams.cdn-surfline.com/cdn-int/pt-supertubes/playlist.m3u8'
    // },
    {
      name: 'Peniche - Super Tubos (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/supertubos/playlist.m3u8'
    },
    {
      name: 'São Torpes (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/saotorpes/playlist.m3u8'
    },
    // {
    //   name: 'São Torpes (Surfline)',
    //   url: 'https://cams.cdn-surfline.com/cdn-int/pt-storpespier/playlist.m3u8'
    // },
    // {
    //   name: 'Nazare (Surfline)',
    //   url: 'https://cams.cdn-surfline.com/cdn-int/pt-nazareov/playlist.m3u8'
    // },
    {
      name: 'Nazare - Praia do Norte (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/canhaonazare/playlist.m3u8'
    },
    {
      name: 'Nazare - Panoramic (BeachCam)',
      url: 'https://video-auth1.iol.pt/beachcam/nazareparadonorte/playlist.m3u8'
    },
    {
      name: 'Gale',
      url: 'https://video-auth1.iol.pt/beachcam/bcgale/chunks.m3u8'
    }
  ]
}
