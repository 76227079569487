export const fr = {
  country: 'France',
  title: 'France Beach Cams',
  beaches: [
    {
      name: 'Anglet',
      url: 'https://cams.cdn-surfline.com/cdn-int/fr-anglet/playlist.m3u8'
    }
  ]
}
